import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-quick-change/"
            }}>{`Quick-Change`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/connors-tps/"
            }}>{`Connors' TPS`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth with contrarian exposure`}</li>
        <li parentName="ul">{`Type: mean-reversion strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking industry sectors, bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: daily`}</li>
        <li parentName="ul">{`Taxation: 100% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $1,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Mean Kitty`}</em>{` aims for returns matching the overall stock market, albeit at significantly lower volatility. The strategy trades mean-reversion of S&P 500 industry sector ETFs to achieve this objective. This approach leads to short holding periods and a low correlation to the stock market but also requires investors to follow the rebalancing schedule closely. In summary, `}<em parentName="p">{`Mean Kitty`}</em>{` appeals to investors seeking to diversify their stock market exposure without adding unnecessary drag to their portfolio’s performance.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Mean Kitty: A mean-reversion strategy",
                "title": "TuringTrader.com, Mean Kitty: A mean-reversion strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/momentum-mean-reversion/"
            }}>{`Momentum & Mean-Reversion`}</a></li>
        </ul>
      </Aside>
      <h3>{`Strategy Rules`}</h3>
      <p>{`The operation of `}<em parentName="p">{`Mean Kitty`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade S&P 500 and sector ETFs`}</li>
        <li parentName="ul">{`entry`}
          <ul parentName="li">
            <li parentName="ul">{`disqualify ETFs trading below their 200-day moving average or with negative momentum`}</li>
            <li parentName="ul">{`select ETF with highest Sharpe Ratio`}</li>
            <li parentName="ul">{`open position when RSI indicates oversold conditions`}</li>
            <li parentName="ul">{`position size inverse to historical volatility`}</li>
            <li parentName="ul">{`remaining capital held in cash`}</li>
          </ul>
        </li>
        <li parentName="ul">{`exit when`}
          <ul parentName="li">
            <li parentName="ul">{`RSI indicates neutral to overbought conditions`}</li>
            <li parentName="ul">{`hitting the stop-loss price`}</li>
            <li parentName="ul">{`the position is held excessively long`}</li>
          </ul>
        </li>
        <li parentName="ul">{`hold a bond market ETF, while not holding a position in the stock market`}</li>
      </ul>
      <p>{`Like other mean-reversion strategies, `}<em parentName="p">{`Mean Kitty`}</em>{` buys into pullbacks, aiming to profit from prices reverting to their mean within a few short days. This approach is similar to catching a falling knife and bears a substantial risk. The strategy addresses this in multiple ways. For once, preference is given to sectors with higher momentum and lower volatility. Further, positions are scaled back with increasing volatility.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Mean Kitty`}</em>{` is well diversified in terms of individual titles, as it trades broad stock market ETFs. However, as the strategy only invests in a single ETF at a time, it is not diversified across asset classes.`}</p>
      <p>{`Because the strategy is only invested in the stock markets for short periods at a time, `}<em parentName="p">{`Mean Kitty`}</em>{` has a very low beta. Nonetheless, the strategy's tail risk is similar to holding the S&P 500.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`Mean Kitty`}</em>{` trails its benchmark about half of the time. However, it often outperforms in sideways and bearish markets. Since the 2008 recession, `}<em parentName="p">{`Mean Kitty`}</em>{` has beaten the S&P 500 by a wide margin and at less than half the volatility. At the same time, the strategy's returns have been smooth and steady, with a Martin Ratio higher than 5.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Mean Kitty`}</em>{` trades frequently and regularly triggers taxable events. As with all mean-reversion strategies, positions are only held for short periods and never for 12 months or longer. Consequently, investors should expect all capital gains to be taxable at the short-term rate unless applying the strategy to a tax-deferred account.`}</p>
      <p><em parentName="p">{`Mean Kitty`}</em>{` might trade often enough to violate T+2 trading rules. According to our testing, there is only little change to the strategy's results if the closing of positions is postponed until they have been held for at least two days.`}</p>
      <p><em parentName="p">{`Mean Kitty`}</em>{` only holds a single ETF at any moment in time. This results in meager capital requirements; the strategy should work as intended with accounts of $1,000 or more.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, June 2020`}</a>{`: Initial release.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, October 2022`}</a>{`: Updated strategy to use `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` as its out-of-market asset for improved performance in environments of rising rates.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      